<template>
  <div class="winers main__margin-top">
    <div class="winers__title">     
        <h1>Победители этой недели</h1>    
    </div>
    <div class="winers__all">
      <div class="winers__all--marq">
        <div class="winers__all--block" v-for="item in mas.concat(mas)" :key="item.id">
            <div class="winers__all--title"> {{item.name}}  </div>
            <div class="winers__all--phone"> {{item.phone}} </div>
        </div>    
      </div>   
    </div>
    <div class="winers__vis"> Посмотреть всех </div>
  </div>
</template>

<script>
import style from './winers-style.scss';



export default {
  name: 'Winers',
  props: [],
  data(){
    return{
      mas: [{
        id: 1,
        name: 'Тут может быть твое имя',
        phone: '+7(***)**-**-**'
      },
      {
        id: 2,
        name: 'Тут может быть твое имя',
        phone: '+7(***)**-**-**'
      },
      {
        id: 3,
        name: 'Тут может быть твое имя',
        phone: '+7(***)**-**-**'
      },
      {
        id: 4,
        name: 'Тут может быть твое имя',
        phone: '+7(***)**-**-**'
      },
      {
        id: 5,
        name: 'Тут может быть твое имя',
        phone: '+7(***)**-**-**'
      },]
    }
  },
  mounted(){
    
  },
  computed: {
    publicPath(){
      return window.location.origin
    }
  },
  components: {
 
  }
}
</script>
